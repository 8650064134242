import { Card, Col, Flex, Row } from "antd";
import React from "react";
import { useGetStatisticsQuery } from "../../../store/statisticsApiSlice";
import { Area, Pie } from "@ant-design/charts";
import "./Statistics.scss";
import { Loader } from "../../../Components/common/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const Statistics: React.FC = () => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const { data, isLoading, isError } = useGetStatisticsQuery({});
  //   Количество пользователей
  // Количество запросов
  // Количество запросов к каждой нейронке
  // Количество ошибок (запросов без ответов)

  return (
    <Flex vertical gap={24} className="statisticsPage">
      <Flex className="statisticsTitle">Статистика</Flex>
      <Flex vertical gap={24}>
        <Card title="Пользователи">
          <Flex gap={24} style={{ width: "100%" }}>
            {data && !isLoading && (
              <>
                <Flex
                  vertical
                  gap={12}
                  style={{ width: "50%", minHeight: "200px" }}>
                  <Row>
                    <Col span={18} className="statisticsTotal">
                      Всего
                    </Col>
                    <Col span={6} className="statisticsTotal">
                      {data.usersCount.usersCount}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsMonth">
                      Зарегистрировано за последний месяц
                    </Col>
                    <Col span={6} className="statisticsMonth">
                      {data.usersCount.usersCountMonth}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsWeek">
                      Зарегистрировано за последние 7 дней
                    </Col>
                    <Col span={6} className="statisticsWeek">
                      {data.usersCount.usersCountWeek}
                    </Col>
                  </Row>
                </Flex>
                <Flex>
                  <Area
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    data={data.usersCount.graphData}
                    xField={(d: any) => new Date(d.date)}
                    yField="count"
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Card>

        <Card title="Количество диалогов">
          <Flex gap={24}>
          {isLoading &&
          <Loader />
          }
          
          {data && !isLoading && 
            <>
            <Flex vertical style={{width: '100%'}}>

            
            <Flex style={{width: '100%'}}>
              <Flex
                vertical
                gap={12}
                style={{ width: "50%", minHeight: "200px" }}>
                <Row>
                  <Col span={18} className="statisticsTotal">
                    Всего
                  </Col>
                  <Col span={6} className="statisticsTotal">
                    {data.chatsCount.chatsCount}
                  </Col>
                </Row>
                <Row>
                  <Col span={18} className="statisticsMonth">
                    за последний месяц
                  </Col>
                  <Col span={6} className="statisticsMonth">
                    {data.chatsCount.chatsCountMonth}
                  </Col>
                </Row>
                <Row>
                  <Col span={18} className="statisticsWeek">
                    за последние 7 дней
                  </Col>
                  <Col span={6} className="statisticsWeek">
                    {data.chatsCount.chatsCountWeek}
                  </Col>
                </Row>
              </Flex>
              
              <Flex style={{width: '50%'}}>
                <Area
                  theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                  data={data.chatsCount.graphData}
                  xField={(d: any) => new Date(d.date)}
                  yField="count"
                />
              </Flex>
            </Flex>
              <Flex style={{width: '100%', marginTop:'24px'}}>
               
                <Flex vertical style={{width: '30%'}}>
                  <div style={{width: '100%', marginBottom:'12px'}} className="statisticsMonth">
                    За неделю
                  </div>
                  <div>
                    {data.chatsCount.pieDataWeek.map((item: any)=>{
                      return <Row>
                        <Col span={12}>{item.type}</Col>
                        <Col span={6}>{item.percent/100} %</Col>
                        <Col span={6}>{item.count} запросов</Col>
                      </Row>
                    })}
                  </div>
                  {/* <Pie
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    style={{width: '100%'}}
                    data={data.chatsCount.pieDataWeek}
                    angleField="percent"
                    autoFit={true}
                    colorField="type"
                    legend={true}
                    isPercent={true}
                    tooltip= {{
                      title: (item: any)=>{
                            return item.type+':  '+item.count +' запросов'
                          },
                      items: false
                    }}       
                  /> */}
                </Flex>
                <Flex vertical style={{width: '30%'}}>
                <div style={{width: '100%', marginBottom:'12px'}} className="statisticsMonth">
                    За месяц
                  </div>
                  <div>
                    {data.chatsCount.pieDataMonth.map((item: any)=>{
                      return <Row>
                        <Col span={12}>{item.type}</Col>
                        <Col span={6}>{item.percent/100} %</Col>
                        <Col span={6}>{item.count} запросов</Col>
                      </Row>
                    })}
                  </div>
                  {/* <Pie
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    style={{width: '100%'}}
                    data={data.chatsCount.pieDataMonth}
                    
                    angleField="percent"
                    colorField="type"
                    tooltip= {{
                      title: (item: any)=>{
                            return item.type+':  '+item.count+' запросов'
                          },
                      items: false
                    }}
                    // label={{
                    //   text: (item: any)=>{
                    //     return item.type+':  '+item.count/100 + '%'
                    //   },
                    //   style: {
                    //     fontWeight: "bold",
                    //   },
                    // }} */}
                    {/* legend={true}
                    statistic={{
                      title: false,
                      content: {
                        style: {
                          //...
                        },
                        content: 'Custom Label', // Here you can add custom label inside donut pie chart
                      },
                    }} 
                  /> */}
                </Flex>
                <Flex vertical style={{width: '30%'}}>
                <div style={{width: '100%', marginBottom:'12px'}} className="statisticsMonth">
                    За все время
                  </div>

                  <div>
                    {data.chatsCount.pieData.map((item: any)=>{
                      return <Row>
                        <Col span={12}>{item.type}</Col>
                        <Col span={6}>{item.percent/100} %</Col>
                        <Col span={6}>{item.count}&nbsp; запросов</Col>
                      </Row>
                    })}
                  </div>
                  {/* <Pie
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    style={{width: '100%'}}
                    data={data.chatsCount.pieData}
                    angleField="percent"
                    colorField="type"
                    tooltip= {{
                      title: (item: any)=>{
                            return item.type+':  '+item.count+' запросов'
                          },
                      subtitle: ''
                      
                    }}
                    legend={{
                      type:{
                        type: "legends",
                        labelFormatter: (d: any)=>{
                          return d.type+d.percent
                        }


                      }
                    }}
                  /> */}
                </Flex>
                </Flex>
                </Flex>
            </>
          }
          </Flex>
        </Card>
        <Card title="Количество генераций">
          <Flex gap={24}>
            {data && !isLoading && (
              <>
                <Flex
                  vertical
                  gap={12}
                  style={{ width: "50%", minHeight: "200px" }}>
                  <Row>
                    <Col span={18} className="statisticsTotal">
                      Всего
                    </Col>
                    <Col span={6} className="statisticsTotal">
                      {data.messagesCount.messagesCount}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsMonth">
                      за последний месяц
                    </Col>
                    <Col span={6} className="statisticsMonth">
                      {data.messagesCount.messagesCountMonth}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsWeek">
                      за последние 7 дней
                    </Col>
                    <Col span={6} className="statisticsWeek">
                      {data.messagesCount.messagesCountWeek}
                    </Col>
                  </Row>
                  <br/>
                  <div style={{width: '100%', marginBottom:'12px'}} className="statisticsTitle">
                    Сообщения без ответа
                  </div>
                  <Row>
                    <Col span={18} className="statisticsTotal">
                      Всего
                    </Col>
                    <Col span={6} className="statisticsTotal">
                      {data.messagesCount.messagesWithoutAnswers}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsMonth">
                      за последний месяц
                    </Col>
                    <Col span={6} className="statisticsMonth">
                      {data.messagesCount.messagesWithoutAnswersMonth}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsWeek">
                      за последние 7 дней
                    </Col>
                    <Col span={6} className="statisticsWeek">
                      {data.messagesCount.messagesWithoutAnswersWeek}
                    </Col>
                  </Row>

                </Flex>
                <Flex>
                  <Area
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    data={data.messagesCount.graphData}
                    xField={(d: any) => new Date(d.date)}
                    yField="count"
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Card>

        <Card title="Vizard video">
          <Flex gap={24}>
            {data && !isLoading && (
              <>
                <Flex
                  vertical
                  gap={12}
                  style={{ width: "50%", minHeight: "200px" }}>
                  <Row>
                    <Col span={18} className="statisticsTotal">
                      Всего
                    </Col>
                    <Col span={6} className="statisticsTotal">
                      {data.vizardCounts.vizardAll}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsMonth">
                      за последний месяц
                    </Col>
                    <Col span={6} className="statisticsMonth">
                      {data.vizardCounts.vizardMonth}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18} className="statisticsWeek">
                      за последние 7 дней
                    </Col>
                    <Col span={6} className="statisticsWeek">
                      {data.vizardCounts.vizardWeek}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Из них</Col>
                  </Row>
                  {data.vizardCounts.byStatus.map((item: any) => {
                    return (
                      <Row key={item.project_status}>
                        <Col span={18}>{item.project_status}</Col>
                        <Col span={6}>{item.count}</Col>
                      </Row>
                    );
                  })}
                </Flex>
                <Flex>
                  <Area
                    theme={currentAlgorithm==='dark'?"classicDark":'classic'}
                    data={data.vizardCounts.graphData}
                    xField={(d: any) => new Date(d.date)}
                    yField="count"
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};
